export let scrollSentences = [
    "Matteo where are you?",
    "The virus is coming to take you home",
    "go home",
    "get out of my hole",
    "i'm serious",
    "look behind you",
    "yes"
  ]


