import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import rabbit from "./assets/Rabbit.obj";
import { scrollSentences } from "./deephole.js";

let rabbi;
let rot = 0;
let isloaded = false;
let scrollCounter = 0;
let scrollIndex = 0;

/*////////////////////////////////////////*/

let renderCalls = [];
function render() {
  requestAnimationFrame(render);
  renderCalls.forEach((callback) => {
    callback();
  });

  if (isloaded) {
    rabbi.rotation.z = rot;
  }
  rot += 0.01;
}
render();

/*////////////////////////////////////////*/

let scene = new THREE.Scene();

let camera = new THREE.PerspectiveCamera(
  80,
  window.innerWidth / window.innerHeight,
  0.1,
  800
);
camera.position.set(5, 10, 7);

let renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor(0x000000, 0);

renderer.toneMapping = THREE.LinearToneMapping;
renderer.toneMappingExposure = Math.pow(0.94, 5.0);
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFShadowMap;

window.addEventListener(
  "resize",
  function () {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  },
  false
);

document.body.appendChild(renderer.domElement);

function renderScene() {
  renderer.render(scene, camera);
}
renderCalls.push(renderScene);

/* ////////////////////////////////////////////////////////////////////////// */

// let controls = new THREE.OrbitControls( camera );
//
// controls.rotateSpeed = 0.3;
// controls.zoomSpeed = 0.9;
//
// controls.minDistance = 3;
// controls.maxDistance = 20;
//
// controls.minPolarAngle = 0; // radians
// controls.maxPolarAngle = Math.PI /2; // radians
//
// controls.enableDamping = true;
// controls.dampingFactor = 0.05;
//
// renderCalls.push(function(){
//   controls.update()
// });

/* ////////////////////////////////////////////////////////////////////////// */

let light = new THREE.PointLight(0xffffcc, 4, 200);
light.position.set(4, 15, -20);
scene.add(light);

let light2 = new THREE.AmbientLight(0x20202a, 6, 100);
// light2.position.set( 30, -10, 30 );
scene.add(light2);

/* ////////////////////////////////////////////////////////////////////////// */
let meshMaterial = new THREE.MeshPhongMaterial({
  color: 0x29c344,
  emissive: 0x2ca441,
  specular: 0x111111,
  shininess: 47,
});

let objLoader = new OBJLoader();

objLoader.load(rabbit, function (object) {
  rabbi = object;
  object.traverse(function (child) {
    if (child instanceof THREE.Mesh) {
      child.material = meshMaterial;
    }
  });

  object.position.set(0, -40, -120);
  object.scale.set(0.5, 0.5, 0.5);
  object.rotation.set(Math.PI / -2, 0, 0);

  scene.add(object);
  isloaded = true;
});

window.onscroll = (e) => {
  let scroll = window.pageYOffset;
  let height = document.documentElement.scrollHeight - window.innerHeight - 1;
  let surface = document.querySelector(".bottom").offsetTop;

  if (scroll > height) {
    if (scrollIndex < scrollSentences.length - 1) {
      scrollCounter++;
    }
    window.scrollTo(0, surface);
  }
  if (scrollCounter > 0 && scrollCounter % 8 == 0) {
    scrollCounter++;
    scrollIndex++;
    nextSentence(scrollIndex);
  }
};

let nextSentence = (i) => {
  let divs = document.querySelectorAll(".deep");
  divs.forEach((div) => {
    div.innerHTML = scrollSentences[i];
  });
};
